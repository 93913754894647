<template>
    <div class="myAnnualFeeOrder">
      <div class="report_con_item">
        <h6 style="margin-bottom: 30px">订单状态</h6>
        <el-steps align-center v-if="detailData.annuityOrderStatus != 60" :space="170" :active="detailData.annuityOrderStatus/10" finish-status="success">
          <el-step title="支付成功"></el-step>
          <el-step title="确认信息中"></el-step>
          <el-step title="提交官费中"></el-step>
          <el-step title="等待官方收据"></el-step>
          <el-step title="订单完成"></el-step>
        </el-steps>

        <el-steps align-center v-else :space="170" :active="3" finish-status="success">
          <el-step title="支付成功"></el-step>
          <el-step title="确认信息中"></el-step>
          <el-step title="退款成功"></el-step>
        </el-steps>
      </div>
      <div class="report_con_item">
        <h6>订单信息</h6>
        <div class="report_list_item">
          <span>订单编号:</span>
          <p>{{detailData.annuityOrderNumber}}</p>
        </div>
        <div class="report_list_item">
          <span>创建时间:</span>
          <p>{{detailData.orderCreateTime}}</p>
        </div>
        <div class="report_list_item">
          <span>订单金额:</span>
          <p>{{detailData.orderSummaryAmount+"元"}}</p>
        </div>
      </div>
      <div class="report_con_item">
        <h6>年费信息</h6>
        <div class="maturity">
          <ul class="maturity_title">
            <li>
              <span style="margin-right: 25px">专利名称</span>
              <span style="margin-right: 25px">专利号</span>
              <span>专利年度</span>
              <span>专利年费</span>
              <span>滞纳金</span>
              <span>待权利恢复费</span>
              <span>代理费</span>
              <span>年费减免</span>
              <span>年费总计</span>
            </li>
          </ul>
          <ul >
            <li :key="index" v-for="(item,index) in detailTableData">
              <el-tooltip class="item" effect="dark" :content="item.patentName" placement="top">
                <span style="margin-right: 25px">{{item.patentName}}</span>
              </el-tooltip>

              <span style="margin-right: 25px">{{item.patentApplyNo}}</span>
              <span>{{item.patentYear}}</span>
              <span>{{getPatentBasicFee(item)}}</span>
              <span>{{item.annualLateFee}}</span>
              <span>{{item.patentRestorationFee}}</span>
              <span>{{item.agentFee}}</span>
              <span>{{item.annualFeeReduction}}%</span>
              <span>{{item.annualFeeTotal}}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="report_con_item">
        <h6>支付信息</h6>
        <div class="report_list_item">
          <span>支付时间:</span>
          <p>{{detailData.orderPaymentTime == null ? '--' : detailData.orderPaymentTime}}</p>
        </div>
        <div class="report_list_item">
          <span>支付方式:</span>
          <span>{{detailData.orderPaymentType == 0 ? '未知' : detailData.orderPaymentType == 1 ? '支付宝' : detailData.orderPaymentType == 2 ? '微信' : '对公支付'}}</span>
        </div>
        <div class="report_list_item">
          <span>支付金额:</span>
          <p>
            {{(detailData.isPaid == 'n' && detailData.orderSummaryAmount == 0) || detailData.orderSummaryAmount == null  ? '--' : detailData.orderSummaryAmount+"元"}}
          </p>
        </div>
      </div>
    </div>
</template>

<script>
    export default {
      name: "myAnnualFeeOrder",
      data(){
          return {
            detailTableData: [],
            detailData: {},
            id: null
          }
      },
      mounted() {
        this.id = this.$route.query.id
        this.detailAjax(this.id)
      },
      methods:{
        getPatentBasicFee(row){
          let patentType = ""
          if (row.annuityName.startsWith("发明")){
            patentType = 0
          }
          else if(row.annuityName.startsWith("实用新型")){
            patentType = 1
          }
          else {
            patentType = 2
          }

          switch (patentType){
            case 0:
              //发明专利
              switch (row.patentYear){
                case 1:
                case 2:
                case 3:
                  return 900
                case 4:
                case 5:
                case 6:
                  return 1200
                case 7:
                case 8:
                case 9:
                  return 2000
                case 10:
                case 11:
                case 12:
                  return 4000
                case 13:
                case 14:
                case 15:
                  return 6000
                case 16:
                case 17:
                case 18:
                case 19:
                case 20:
                  return 8000
                default:
                  return ""
              }
            case 1:
            case 2:
              //实用新型专利、外观设计专利
              switch (row.patentYear){
                case 1:
                case 2:
                case 3:
                  return 600
                case 4:
                case 5:
                  return 900
                case 6:
                case 7:
                case 8:
                  return 1200
                case 9:
                case 10:
                  return 2000
                default:
                  return ""
              }
            default:
              return ""
          }
        },
        // 详情
        async detailAjax (platformOrderNumber) {
          let {data} = await this.$axios.get(`/custody/annuity/order/detail/${platformOrderNumber}`);
          if (data.code == 0) {
            this.detailData = data.data
            this.detailTableData = data.data.annuityDetailDtoList
          }
        },
      }
    }
</script>

<style lang="less" scoped>
  .myAnnualFeeOrder {
    height: 767px;
    overflow-y: auto;
    padding: 20px 40px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.08);
    border-radius: 15px 15px 15px 15px;
    .report_con_item {
      /deep/.el-step__head.is-success {
        color: #386FFC;
        border-color:#386FFC!important;
        .el-step__icon.is-text {
          background: #386FFC;
        }
        .el-step__icon-inner.is-status.el-icon-check {
          color: white;
        }
      }
      /deep/.el-step__main {
        .el-step__title.is-success {
          color: #2D3748!important;
        }
      }
      margin-bottom: 50px;
      .maturity {
        padding: 30px 21px 20px;
        box-sizing: border-box;
        background: #E4EDFF;
        border-radius: 5px 5px 5px 5px;
        .maturity_title {
          width: calc(100% - 10px);
          margin-bottom: 11px;
          li {
            display: flex;
            padding: 0px 10px;
            box-sizing: border-box;
            span {
              text-align: left;
              display: block;
              width: 10%;
              font-weight: bold;
              font-size: 14px;
              color: #2D3748;
              &:nth-of-type(1) {
                width: 20%;
              }
              &:nth-last-of-type(1) {
                width: 10%;
              }
            }
          }
        }
        ul:nth-of-type(2) {
          width: 100%;
          max-height: 160px;
          margin-bottom: 10px;
          background: #E4EDFF;
          overflow-y: auto;
          //result-ico
          // 滚动条的宽度
          &::-webkit-scrollbar {
            width: 10px; // 横向滚动条
            height: 10px; // 纵向滚动条 必写
          }

          // 滚动条的滑块
          &::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, .1);
            border-radius: 10px
          }

          &::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, .1)
          }
          li {
            padding: 24px 11px;
            box-sizing: border-box;
            height: 40px;
            background: #FFFFFF;
            box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.08);
            border-radius: 8px;
            display: flex;
            align-items: center;
            margin-bottom: 11px;
            span {
              text-align: left;
              display: block;
              align-items: center;
              white-space: nowrap;        /* 禁止换行 */
              overflow: hidden;           /* 隐藏溢出内容 */
              text-overflow: ellipsis;    /* 显示省略号 */
              font-weight: 500;
              font-size: 14px;
              color: #434E5F;
              width: 10%;
              &:nth-of-type(1) {
                padding-right: 5px;
                box-sizing: border-box;
                width: 20%;
              }
              &:nth-last-of-type(1) {
                width: 10%;
              }
              &.status {
                i.you {
                  width: 53px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #4F5AED;
                  background: #EAECFF;
                  border-radius: 30px;
                }
                i.err {
                  width: 53px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 400;
                  font-size: 12px;
                  color: #D12953;
                  background: #FAF0F3;
                  border-radius: 30px;
                }
              }
            }
          }
        }

      }
      h6 {
        color: #424242;
        font-size: 16px;
        position: relative;
        margin-bottom: 12px;
        &::before {
          content: "";
          width: 4px;
          height: 18px;
          background-color: @blue;
          position: absolute;
          top: 50%;
          margin-top: -9px;
          left: -11px;
        }
      }
      .table {
        background: #E4EDFF;
        border-radius: 8px;
        padding: 0px 20px;
        overflow: hidden !important;
        /deep/.vxe_grid_header th{
          font-weight: 600;
          font-size: 14px;
          color: #464F60;
          height: 70px;
          background: #E4EDFF!important;
        }
        /deep/.el-table__header-wrapper {
          background: #E4EDFF!important;
        }
        /deep/.el-table__body-wrapper {
          background: #E4EDFF!important;
        }
      }
      .report_list_item {
        line-height: 28px;
        span {
          display: inline-block;
          width: 74px;
          font-size: 14px;
          color: #2D3748;
        }
        p {
          color: #2D3748;
          display: inline-block;
          width: 600px;
          vertical-align: top;
        }
      }
    }
  }
</style>
